import React, { useState } from "react";
import "./styles/instrucciones.scss";

function Instrucciones() {
  const [expanded, setExpanded] = useState(false);

  const toggleExpansion = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={`instrucciones-container ${expanded ? "expanded" : ""}`}>
      <p>
        Compara las mejores <span>tarifas fijas en mercado libre</span>, con las
        empresas más importantes del sector.
      </p>
      {expanded && (
        <>
          <p>
            Este comparador está pensado para <span>clientes residencial</span>{" "}
            y con <span>menos de 10kw de potencia</span>. Si tu potencia es
            superior habrá algunos precios que no serán 100% exactos, pero sí
            orientativos.
          </p>
          <p>
            Es muy sencillo, sólo tienes que poner en cada casilla los{" "}
            <span>datos que se te requieren.</span> Todos vienen dentro de tu
            factura.
          </p>

          <p>
            Si tu potencia contratada es la misma en los dos períodos,{" "}
            <span>ponla tanto en el P1 como en el P2.</span>
          </p>
          <p>
            Hay tres tipos de consumo a pesar de que{" "}
            <span>puedas tener un solo precio</span>. Estos son: punta, llano y
            valle; yendo de más caro a más barato.
          </p>

          <p>
            En otros importes comprueba los que son{" "}
            <span>antes de IVA y los que son después de IVA</span> para saber
            donde colocarlos. Los que son después de{" "}
            <span>IVA suelen ser servicios de mantenimiento.</span>
          </p>
          <p>
            Estos importes se incluirán en la comparativa de factura para
            equipararla, pero debes tener en cuenta que los servicios de
            mantenimiento <span>no son obligatorios</span> y puedes contratarlos
            con cualquier compañía. Tendrás que ver qué precios tienen en cada
            una de ellas si te interesase un servicio.
          </p>
        </>
      )}
      <div className="button-container">
        {!expanded && (
          <button className="expand-button" onClick={toggleExpansion}>
            Mostrar más
          </button>
        )}
        {expanded && (
          <button className="expand-button" onClick={toggleExpansion}>
            Mostrar menos
          </button>
        )}
      </div>
    </div>
  );
}

export default Instrucciones;
