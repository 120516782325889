import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Result from "./Result";
import "./styles/energyForm.scss";
import { Loading } from "./Loading";

const validationSchema = Yup.object().shape({
  dias_fact: Yup.number().required("Los días de facturación son necesarios"),
  potencia_P1: Yup.number().required("La potencia contratada es necesaria"),
  potencia_P2: Yup.number().required("La potencia contratada es necesaria"),
  consumo_Punta: Yup.number().required("El consumo es necesario"),
  consumo_Llano: Yup.number().required("El consumo es necesario"),
  consumo_Valle: Yup.number().required("El consumo es necesario"),
  alquilerEquipo: Yup.number().required("El alquiler de equipo es necesario"),
});

const EnergyForm = ({ precios }) => {
  const [result, setResult] = useState(0);
  const [isLoading, setIsLoading] = useState();

  const handleSubmit = (values, { resetForm }) => {
    setIsLoading(true);

    setTimeout(() => {
      const {
        dias_fact,
        potencia_P1,
        potencia_P2,
        consumo_Punta,
        consumo_Llano,
        consumo_Valle,
        alquilerEquipo,
        antesIVA,
        despuesIVA,
      } = values;
      const calculatedCosts = Object.entries(precios).reduce(
        (acc, [company, tariffs]) => {
          acc[company] = {};

          Object.entries(tariffs).forEach(([tariffName, tariffData]) => {
            const potenciaCost_P1 =
              parseFloat(potencia_P1) *
              parseFloat(tariffData.precio_Potencia_P1) *
              parseFloat(dias_fact);
            const potenciaCost_P2 =
              parseFloat(potencia_P2) *
              parseFloat(tariffData.precio_Potencia_P2) *
              parseFloat(dias_fact);

            const consumoPuntaCost =
              parseFloat(consumo_Punta) * parseFloat(tariffData.precio_Punta);
            const consumoLlanoCost =
              parseFloat(consumo_Llano) * parseFloat(tariffData.precio_Llano);
            const consumoValleCost =
              parseFloat(consumo_Valle) * parseFloat(tariffData.precio_Valle);

            const energiaCost = parseFloat(tariffData.precio_Energia)
              ? (parseFloat(consumo_Punta) +
                  parseFloat(consumo_Llano) +
                  parseFloat(consumo_Valle)) *
                parseFloat(tariffData.precio_Energia)
              : 0;

            const isSinglePrice = parseFloat(tariffData.precio_Energia)
              ? true
              : false;

            const subtotal = isSinglePrice
              ? (potenciaCost_P1 + potenciaCost_P2 + energiaCost) * 1.005
              : (potenciaCost_P1 +
                  potenciaCost_P2 +
                  consumoPuntaCost +
                  consumoLlanoCost +
                  consumoValleCost) *
                1.005;

            let totalAntesIVA =
              subtotal + parseFloat(alquilerEquipo) + parseFloat(antesIVA);
            let totalConIVA = totalAntesIVA * 1.1;

            if (antesIVA === "") {
              totalAntesIVA = subtotal + parseFloat(alquilerEquipo);
              totalConIVA = totalAntesIVA * 1.1;
            }

            let total = totalConIVA + parseFloat(despuesIVA);

            if (despuesIVA === "") {
              total = totalConIVA;
            }

            acc[company][tariffName] = {
              potenciaCost_P1: isNaN(potenciaCost_P1) ? 0 : potenciaCost_P1,
              potenciaCost_P2: isNaN(potenciaCost_P2) ? 0 : potenciaCost_P2,
              consumoPuntaCost: isNaN(consumoPuntaCost) ? 0 : consumoPuntaCost,
              consumoLlanoCost: isNaN(consumoLlanoCost) ? 0 : consumoLlanoCost,
              consumoValleCost: isNaN(consumoValleCost) ? 0 : consumoValleCost,
              energiaCost: isNaN(energiaCost) ? 0 : energiaCost,
              subtotal: isNaN(subtotal) ? 0 : subtotal,
              totalAntesIVA: isNaN(totalAntesIVA) ? 0 : totalAntesIVA,
              totalConIVA: isNaN(totalConIVA) ? 0 : totalConIVA,
              total: isNaN(total) ? 0 : total,
              isSinglePrice: isSinglePrice,
              URL: tariffData.URL,
            };
          });

          return acc;
        },
        {}
      );

      setResult(calculatedCosts);
      setIsLoading(false);
      resetForm();
    }, 1000);
  };

  return (
    <section className={`form ${isLoading ? "loading-overlay" : ""}`}>
      <Formik
        initialValues={{
          dias_fact: "",
          potencia_P1: "",
          potencia_P2: "",
          consumo_Punta: "",
          consumo_Llano: "",
          consumo_Valle: "",
          alquilerEquipo: "",
          antesIVA: "",
          despuesIVA: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form className="energy-form">
            <fieldset>
              <legend>Introduce los datos de tu factura</legend>
              <ul className="cont-form">
                <li>
                  <label htmlFor="dias_fact" className="dias">
                    Días de facturación
                  </label>
                  <Field
                    className="numberArea"
                    type="number"
                    name="dias_fact"
                    id="dias_fact"
                    placeholder="Introduce los días de facturación"
                    min="1"
                    step="0.01"
                    required
                    disabled={formikProps.isSubmitting}
                  />
                  <ErrorMessage name="dias_fact" />
                </li>
                <li>
                  <label htmlFor="potencia_P1" className="potencia">
                    Potencia P1
                  </label>
                  <Field
                    className="numberArea"
                    type="number"
                    name="potencia_P1"
                    id="potencia_P1"
                    placeholder="Introduce tu potencia P1 contratada"
                    min="0.1"
                    step="0.01"
                    required
                  />
                  <ErrorMessage name="potencia_P1" />
                </li>
                <li>
                  <label htmlFor="potencia_P2" className="potencia">
                    Potencia P2
                  </label>
                  <Field
                    className="numberArea"
                    type="number"
                    name="potencia_P2"
                    id="potencia_P2"
                    placeholder="Introduce tu potencia P2 contratada"
                    min="0.1"
                    step="0.01"
                    required
                  />
                  <ErrorMessage name="potencia_P2" />
                </li>
                <li>
                  <label htmlFor="consumo_Punta" className="consumo">
                    Consumo Punta
                  </label>
                  <Field
                    className="numberArea"
                    type="number"
                    name="consumo_Punta"
                    id="consumo_Punta"
                    placeholder="Introduce tu consumo en P1 (Punta)"
                    min="0"
                    step="0.01"
                    required
                  />
                  <ErrorMessage name="consumo_Punta" />
                </li>
                <li>
                  <label htmlFor="consumo_Llano" className="consumo">
                    Consumo Llano
                  </label>
                  <Field
                    className="numberArea"
                    type="number"
                    name="consumo_Llano"
                    id="consumo_Llano"
                    placeholder="Introduce tu consumo en P2 (Llano)"
                    min="0"
                    step="0.01"
                    required
                  />
                  <ErrorMessage name="consumo_Llano" />
                </li>
                <li>
                  <label htmlFor="consumo_Valle" className="consumo">
                    Consumo Valle
                  </label>
                  <Field
                    className="numberArea"
                    type="number"
                    name="consumo_Valle"
                    id="consumo_Valle"
                    placeholder="Introduce tu consumo en P3 (Valle)"
                    min="0"
                    step="0.01"
                    required
                  />
                  <ErrorMessage name="consumo_Valle" />
                </li>
                <li>
                  <label htmlFor="alquilerEquipo" className="alquiler">
                    Alquiler Equipo
                  </label>
                  <Field
                    className="numberArea"
                    type="number"
                    name="alquilerEquipo"
                    id="alquilerEquipo"
                    placeholder="Introduce el coste del alquiler de equipo"
                    min="0"
                    step="0.01"
                    required
                  />
                  <ErrorMessage name="alquilerEquipo" />
                </li>
                <li>
                  <label htmlFor="antesIVA" className="otrosimportes">
                    Otros importes antes de IVA
                  </label>
                  <Field
                    className="numberArea"
                    type="number"
                    name="antesIVA"
                    id="antesIVA"
                    placeholder="Introduce otros importes antes de IVA (si los hay)"
                    min="0"
                    step="0.01"
                  />
                  <ErrorMessage name="antesIVA" />
                </li>
                <li>
                  <label htmlFor="despuesIVA" className="otrosimportes">
                    Otros importes después de IVA
                  </label>
                  <Field
                    className="numberArea"
                    type="number"
                    name="despuesIVA"
                    id="despuesIVA"
                    placeholder="Introduce otros importes después de IVA (si los hay)"
                    min="0"
                    step="0.01"
                  />
                  <ErrorMessage name="despuesIVA" />
                </li>
              </ul>

              <button
                className="button"
                type="submit"
                value="submit"
                disabled={formikProps.isSubmitting}
              >
                Enviar
              </button>
            </fieldset>
          </Form>
        )}
      </Formik>
      {isLoading ? <Loading /> : <Result result={result} />}
    </section>
  );
};

export default EnergyForm;
