import "./styles/footer.scss";

export const Footer = () => {
  return (
    <footer>
      <p className="mailto">
        ¿Todavía tienes dudas? Pues no dudes en ponerte en contacto conmigo
        dirigiéndote a:
      </p>
      <address>
        <a href="mailto:info@ramonviqueira.com">info@ramonviqueira.com</a>
      </address>
    </footer>
  );
};
