import axios from "axios";

const transformData = (data) => {
  return {
    Naturgy: {
      "Tarifa Por Uso": {
        precio_Potencia_P1: data.naturgyResult.precio_potencia_P1,
        precio_Potencia_P2: data.naturgyResult.precio_potencia_P2,
        precio_Energia: data.naturgyResult.precio_energia,
        URL: data.naturgyResult.url_por_uso_luz,
      },
      "Tarifa Noche": {
        precio_Potencia_P1: data.naturgyResult.precio_potencia_noche_P1,
        precio_Potencia_P2: data.naturgyResult.precio_potencia_noche_P2,
        precio_Punta: data.naturgyResult.precio_Punta,
        precio_Llano: data.naturgyResult.precio_Llano,
        precio_Valle: data.naturgyResult.precio_Valle,
        URL: data.naturgyResult.url_noche,
      },
    },

    Repsol: {
      "Tarifa Ahorro Plus": {
        precio_Potencia_P1: data.repsolResult.precio_potencia_P1,
        precio_Potencia_P2: data.repsolResult.precio_potencia_P2,
        precio_Energia: data.repsolResult.precio_energia,
        URL: data.repsolResult.url_ahorro,
      },
      "Tarifa Discriminación Horaria": {
        precio_Potencia_P1: data.repsolResult.precio_potencia_DH_P1,
        precio_Potencia_P2: data.repsolResult.precio_potencia_DH_P2,
        precio_Punta: data.repsolResult.precio_Punta,
        precio_Llano: data.repsolResult.precio_Llano,
        precio_Valle: data.repsolResult.precio_Valle,
        URL: data.repsolResult.url_DH,
      },
    },

    "Total Energies": {
      "A Tu Aire Luz Siempre": {
        precio_Potencia_P1: data.totalEnergiesResult.precio_potencia_P1,
        precio_Potencia_P2: data.totalEnergiesResult.precio_potencia_P2,
        precio_Energia: data.totalEnergiesResult.precio_energia,
        URL: data.totalEnergiesResult.url_aire,
      },
      "Luz Programa tu Ahorro": {
        precio_Potencia_P1: data.totalEnergiesResult.precio_potencia_noche_P1,
        precio_Potencia_P2: data.totalEnergiesResult.precio_potencia_noche_P2,
        precio_Punta: data.totalEnergiesResult.precio_Punta,
        precio_Llano: data.totalEnergiesResult.precio_Llano,
        precio_Valle: data.totalEnergiesResult.precio_Valle,
        URL: data.totalEnergiesResult.url_DH,
      },
    },

    Endesa: {
      "Tarifa Conecta": {
        precio_Potencia_P1: data.endesaResult.precio_potencia_conecta_P1,
        precio_Potencia_P2: data.endesaResult.precio_potencia_conecta_P2,
        precio_Energia: data.endesaResult.precio_energia_conecta,
        URL: data.endesaResult.url_plan_conecta,
      },
      "Tarifa One": {
        precio_Potencia_P1: data.endesaResult.precio_potencia_one_P1,
        precio_Potencia_P2: data.endesaResult.precio_potencia_one_P2,
        precio_Energia: data.endesaResult.precio_tarifa_one,
        URL: data.endesaResult.url_tarifa_one,
      },

      "Tarifa One 3": {
        precio_Potencia_P1: data.endesaResult.precio_potencia_one3_P1,
        precio_Potencia_P2: data.endesaResult.precio_potencia_one3_P2,
        precio_Punta: data.endesaResult.precio_Punta,
        precio_Llano: data.endesaResult.precio_Llano,
        precio_Valle: data.endesaResult.precio_Valle,
        URL: data.endesaResult.url_tarifa_one3,
      },
    },

    Iberdrola: {
      "Plan Estable": {
        precio_Potencia_P1: data.iberdrolaResult.precio_potencia_estable_P1,
        precio_Potencia_P2: data.iberdrolaResult.precio_potencia_estable_P2,
        precio_Energia: data.iberdrolaResult.precio_plan_estable,
        URL: data.iberdrolaResult.url_plan_estable,
      },
      "Plan Online": {
        precio_Potencia_P1: data.iberdrolaResult.precio_potencia_online_P1,
        precio_Potencia_P2: data.iberdrolaResult.precio_potencia_online_P2,
        precio_Energia: data.iberdrolaResult.precio_energia_online,
        URL: data.iberdrolaResult.url_plan_online,
      },
    },
  };
};

const fetchPrecios = async () => {
  // const response = await axios.get("http://localhost:4000/api/data");
  const response = await axios.get(
    "https://checkeleckback.ramonviqueira.com/api/data"
  );

  const transformedData = transformData(response.data);
  return transformedData;
};

export default fetchPrecios;
