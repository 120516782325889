import React, { useState, useEffect } from "react";
import "./App.css";
import fetchPrecios from "./services/fetchPrecios";
import EnergyForm from "./components/EnergyForm";
import Header from "./components/Header";
import { Footer } from "./components/Footer";
import Instrucciones from "./components/Instrucciones";
import { Loading } from "./components/Loading";

function App() {
  const [precios, setPrecios] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false); // Nuevo estado

  useEffect(() => {
    const obtenerPrecios = async () => {
      setLoading(true);
      try {
        const preciosData = await fetchPrecios();
        setPrecios(preciosData);
      } catch (error) {
        console.error("Error al obtener los datos: ", error);
        setError(true); // Si hay un error, se establece a true
      }
      setLoading(false);
    };
    obtenerPrecios();
  }, []);

  return (
    <>
      <Header />
      <Instrucciones />
      {loading ? (
        <Loading />
      ) : error ? (
        <div>Error al obtener los datos</div> // Si hay un error, muestra este mensaje
      ) : (
        <EnergyForm precios={precios} />
      )}
      <Footer />
    </>
  );
}

export default App;
