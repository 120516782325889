import React, { useState } from "react";
import "./styles/result.scss";

const Result = ({ result }) => {
  const [expandedTariffs, setExpandedTariffs] = useState([]);

  //Función para cargar la imagen y cargar una random si no está disponible.
  const cargarImagen = (path) => {
    const images = require.context("../assets/", true);
    try {
      return images(`./${path}`);
    } catch (error) {
      return images("./random.jpg");
    }
  };

  function sortTariffsByTotal(a, b) {
    if (a.data.total < b.data.total) {
      return -1;
    }
    if (a.data.total > b.data.total) {
      return 1;
    }
    return 0;
  }

  const toggleTariff = (tariffName) => {
    if (expandedTariffs.includes(tariffName)) {
      setExpandedTariffs(expandedTariffs.filter((item) => item !== tariffName));
    } else {
      setExpandedTariffs([...expandedTariffs, tariffName]);
    }
  };
  const renderTariffs = (tariffs) => {
    return tariffs.map((tariffData) => (
      <div
        key={`${tariffData.company}-${tariffData.tariffName}`}
        className="tariff-card"
      >
        <h4>{tariffData.tariffName}</h4>
        <img
          src={cargarImagen(`${tariffData.company}.png`)}
          alt={tariffData.company}
        />

        <h1>
          {tariffData.data.total.toFixed(2) === "0.00"
            ? "No se han podido cargar los datos"
            : `Total: ${tariffData.data.total.toFixed(2)}€`}
        </h1>

        <button
          className="expand-button"
          onClick={() => toggleTariff(tariffData.tariffName)}
        >
          {expandedTariffs.includes(tariffData.tariffName)
            ? "Ver menos"
            : "Ver más"}
        </button>
        <div
          className={`expanded-content ${
            expandedTariffs.includes(tariffData.tariffName) ? "open" : ""
          }`}
        >
          {" "}
          {expandedTariffs.includes(tariffData.tariffName) && (
            <>
              <p>Potencia P1: {tariffData.data.potenciaCost_P1.toFixed(2)}€</p>
              <p>Potencia P2: {tariffData.data.potenciaCost_P2.toFixed(2)}€</p>
              {!tariffData.data.isSinglePrice && (
                <>
                  <p>
                    Consumo Punta: {tariffData.data.consumoPuntaCost.toFixed(2)}
                    €
                  </p>
                  <p>
                    Consumo Llano: {tariffData.data.consumoLlanoCost.toFixed(2)}
                    €
                  </p>
                  <p>
                    Consumo Valle: {tariffData.data.consumoValleCost.toFixed(2)}
                    €
                  </p>
                </>
              )}
              {tariffData.data.isSinglePrice && (
                <p>Energía: {tariffData.data.energiaCost.toFixed(2)}€</p>
              )}
              <p>
                Subtotal con Impuesto Energético:{" "}
                {tariffData.data.subtotal.toFixed(2)}€
              </p>
              <p>
                Total antes de IVA: {tariffData.data.totalAntesIVA.toFixed(2)}€
              </p>
              <p>Total factura: {tariffData.data.total.toFixed(2)}€</p>
              <p className="contrata">
                <a
                  href={tariffData.data.dataURL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Contrata aquí
                </a>
              </p>
            </>
          )}
        </div>
      </div>
    ));
  };

  const allTariffs = Object.entries(result)
    .flatMap(([company, tariffs]) =>
      Object.entries(tariffs).map(([tariffName, data]) => {
        return {
          company,
          tariffName,
          data: {
            ...data,
            dataURL: data.URL, // Aquí añades la URL a data con la clave 'dataURL'
          },
        };
      })
    )
    .sort(sortTariffsByTotal);

  return (
    <section className="tariff-cards">{renderTariffs(allTariffs)}</section>
  );
};

export default Result;
