import "./styles/header.scss";
import linkedinIcon from "../assets/linkedin-icon.png";
import githubIcon from "../assets/github-icon.png";

function Header() {
  const date = new Date();
  return (
    <header>
      <div className="message-header">
        <p>
          Precios actualizados a fecha <br></br>
          <span className="fecha">{date.toLocaleDateString()}</span>
        </p>
      </div>
      <div className="name-header">
        <h1>Checkelec</h1>
        <h2>Code by Ramón Viqueira</h2>
      </div>

      <nav className="cont-nav">
        <a
          href="https://www.linkedin.com/in/ramon-viqueira/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={linkedinIcon} alt="Linkedin" className="icon" />
        </a>

        <a
          href="https://github.com/nyxelviqueira"
          target="_blank"
          rel="noreferrer"
        >
          <img src={githubIcon} alt="Github" className="icon" />
        </a>
      </nav>
    </header>
  );
}

export default Header;
